<template>
  <div class="scene" data-id="scene" :data-scene="$route.params.scene">
    <BaseTitleText :content="$translate($route.params.scene).titleText" />

    <BaseCta
      :content="$translate($route.params.scene).cta"
      :to="`/${$route.params.lang}/${$translate($route.params.scene).nextLink}`"
      @click.native="onClick"
    />
  </div>
</template>

<script>
import BaseTitleText from '@/components/BaseTitleText';
import BaseCta from '@/components/BaseCta';

export default {
  name: 'Home',

  components: {
    BaseTitleText,
    BaseCta,
  },

  methods: {
    onClick() {
      this.$audio.play('click');
      this.$sendEvent('Start game', this.$route.params.scene);
    },
  },
};
</script>
